import { __awaiter } from "tslib";
import * as Sentry from '@sentry/react';
import { selectors as coreSelectors } from 'mage-core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toCamelCase } from '../utils';
const CREDENTIALS_BASE = '/external/client-identifiers';
export default function useClientKeys({ mid, region: argsRegion, fetchingClientKeysInitially = true, createIfNoneExist = false }) {
    var _a, _b;
    const createClient = useSelector(coreSelectors.createBackendClient);
    const globalCreateIfNoneExist = !!createIfNoneExist;
    const getMerchantRegion = useSelector(coreSelectors.getMerchantRegion);
    const client = useMemo(() => createClient('credentials'), [createClient]);
    const midRegion = useMemo(() => getMerchantRegion(mid), [getMerchantRegion, mid]);
    const region = argsRegion !== null && argsRegion !== void 0 ? argsRegion : midRegion;
    const [isLoading, setIsLoading] = useState(false);
    const [clientKeys, setClientKeys] = useState([]);
    const clientKey = (_b = (_a = clientKeys[0]) === null || _a === void 0 ? void 0 : _a.clientToken) !== null && _b !== void 0 ? _b : null;
    const [error, setError] = useState(null);
    const setErrorWithSentry = useCallback((e, message) => {
        const errorId = Sentry.captureException(e);
        const error = { id: errorId, message: message !== null && message !== void 0 ? message : e.message };
        setError(error);
        return error;
    }, []);
    const api = {
        getExistingClientKeys: useCallback((createIfNoneExist = false) => {
            const queryParams = new URLSearchParams({ mid });
            createIfNoneExist && queryParams.set('createIfNoneExist', 'true');
            const url = `${CREDENTIALS_BASE}?${queryParams}`;
            return client.request({ method: 'GET', url, region })
                .then(res => toCamelCase(res.data));
        }, [client, mid, region]),
        createClientKey: useCallback(() => {
            return client.request({
                method: 'POST',
                url: CREDENTIALS_BASE,
                data: {
                    mid
                },
                region
            }).then(res => toCamelCase(res.data));
        }, [client, mid, region])
    };
    const getClientKeys = useCallback((...args_1) => __awaiter(this, [...args_1], void 0, function* ({ createIfNoneExist } = {}) {
        if (!mid)
            return [];
        try {
            setIsLoading(true);
            const response = yield api.getExistingClientKeys(createIfNoneExist);
            const clientKeys = response.credentials;
            setClientKeys(clientKeys);
            setError(null);
            return clientKeys;
        }
        catch (e) {
            setClientKeys([]);
            // @ts-ignore: TODO: type guard `e`
            const generatedError = setErrorWithSentry(e, 'Failed to get Client identifiers');
            throw generatedError;
        }
        finally {
            setIsLoading(false);
        }
    }), [client, mid, region]);
    const createClientKey = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        let clientKey;
        try {
            setIsLoading(true);
            const createResponse = yield api.createClientKey();
            clientKey = createResponse.secret;
            const getResponse = yield api.getExistingClientKeys();
            const clientKeys = getResponse.credentials;
            setClientKeys(clientKeys);
            setError(null);
            return clientKey;
        }
        catch (e) {
            const message = clientKey ? 'getting Client identifiers' : 'creating Client identifier';
            setClientKeys([]);
            // @ts-ignore: TODO: type guard `e`
            setErrorWithSentry(e, `Failed when ${message}`);
            throw error;
        }
        finally {
            setIsLoading(false);
        }
    }), [client, mid, region]);
    useEffect(() => {
        if (fetchingClientKeysInitially) {
            getClientKeys({ createIfNoneExist: globalCreateIfNoneExist })
                // eslint-disable-next-line no-console
                .catch(console.error);
        }
    }, [getClientKeys, fetchingClientKeysInitially]);
    return {
        isLoading,
        clientKey,
        clientKeys,
        getClientKeys,
        createClientKey,
        error
    };
}
