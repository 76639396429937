import { SpacerVertical } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import styled from 'styled-components';
import { useActiveBoostProducts } from '../../hooks/useActiveBoostProducts';
import BoostApp from './BoostApp';
const TRANSLATION_PREFIX = 'home-fe.boosters-home-v03.configure-boost-features';
const URL_OSM_CONFIG = '/on-site-messaging/dashboard/placements';
const URL_KEC_CONFIG = '/kec/installation';
const URL_SIWIK_CONFIG = '/siwk/config';
export const LinkContainer = styled.div.withConfig({ displayName: "LinkContainer", componentId: "sc-qlqxny" }) `
  display: flex;
  margin-bottom: 5px;
`;
export const AppContainer = styled.div.withConfig({ displayName: "AppContainer", componentId: "sc-j4v0ci" }) `
  display: grid;
`;
export default function BoostApps() {
    const t = i18nHooks.useTranslator();
    const { mustDisplayOSM, mustDisplayKEC, mustDisplaySIWK } = useActiveBoostProducts();
    return (React.createElement(React.Fragment, null,
        mustDisplayOSM && (React.createElement(React.Fragment, null,
            React.createElement(SpacerVertical, { spaceToken: "space.200" }),
            React.createElement(BoostApp, { appId: 'osm', linkLabel: t(`${TRANSLATION_PREFIX}.apps.osm.link-label`), description: t(`${TRANSLATION_PREFIX}.apps.osm.description`), redirectionUrl: URL_OSM_CONFIG }),
            React.createElement(SpacerVertical, { spaceToken: "space.200" }))),
        mustDisplayKEC && (React.createElement(React.Fragment, null,
            React.createElement(BoostApp, { appId: 'kec', linkLabel: t(`${TRANSLATION_PREFIX}.apps.kec.link-label`), description: t(`${TRANSLATION_PREFIX}.apps.kec.description`), redirectionUrl: URL_KEC_CONFIG }),
            React.createElement(SpacerVertical, { spaceToken: "space.200" }))),
        mustDisplaySIWK && (React.createElement(BoostApp, { appId: 'siwk', linkLabel: t(`${TRANSLATION_PREFIX}.apps.siwk.link-label`), description: t(`${TRANSLATION_PREFIX}.apps.siwk.description`), redirectionUrl: URL_SIWIK_CONFIG }))));
}
