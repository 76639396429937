import * as frontEndRouter from './insights/frontendEventRouter';
import * as googleAnalytics from './insights/googleAnalytics';
import * as mParticle from './insights/mParticle';
export const create = () => {
    googleAnalytics.create();
};
export const pageview = (pathname = '/', search = '', title) => {
    googleAnalytics.pageview();
    mParticle.pageview(pathname, search, title);
    frontEndRouter.pageview(pathname, search, title);
};
export const setUser = userData => {
    googleAnalytics.setUser(userData);
    mParticle.setUser(userData);
    frontEndRouter.setUserId(userData === null || userData === void 0 ? void 0 : userData.user_id);
};
export const setRealm = realm => {
    googleAnalytics.setRealm(realm);
    frontEndRouter.setRealm(realm);
};
export const data = (custom = {}) => {
    googleAnalytics.data(custom);
};
export const event = (eventData) => {
    googleAnalytics.event(eventData);
    mParticle.event(eventData);
    frontEndRouter.events(eventData);
};
export const logException = (action = {}) => {
    googleAnalytics.logException(action);
    mParticle.logError(action);
};
export const trackSidebarNavigation = ({ label }) => {
    googleAnalytics.event({
        category: 'menu-sidebar',
        action: 'navigation',
        label
    });
};
