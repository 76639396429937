import { TitleSecondary } from '@klarna/bubble-ui';
import { Flex, useResponsiveContext } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import styled from 'styled-components';
const TitleContainer = styled.div.withConfig({ displayName: "TitleContainer", componentId: "sc-kkkfbo" }) `
  max-width: 100%;
  padding: 0;
`;
const FixedHeightTitleContainer = styled.div.withConfig({ displayName: "FixedHeightTitleContainer", componentId: "sc-1p2schh" }) `
  height: ${(props) => props.isXlDesktop ? '50px' : props.isDesktop ? '60px' : '50px'}
`;
const NoWrap = styled.span.withConfig({ displayName: "NoWrap", componentId: "sc-urh4aw" }) `
  white-space: nowrap;
`;
const spanTransformer = (text) => React.createElement(NoWrap, null, text);
export default function BannerCard({ imageSrc, titleTranslationKey }) {
    const { isXLDesktop, isDesktop } = useResponsiveContext();
    const t = i18nHooks.useTranslator();
    return (React.createElement(Flex, { column: true, style: { width: '30%', gap: '18px' } },
        React.createElement(FixedHeightTitleContainer, { isXlDesktop: isXLDesktop, isDesktop: isDesktop },
            React.createElement(TitleContainer, null,
                React.createElement(TitleSecondary, null, t(titleTranslationKey, { span: spanTransformer })))),
        React.createElement("img", { src: imageSrc, alt: t(titleTranslationKey, { span: spanTransformer }), width: '100%' })));
}
