import { hooks as coreHooks } from 'mage-core';
import useUserAccessibleApps from '../../../../../hooks/useUserAccessibleApps';
import expressCheckoutCoverImage from '../../../assets/app-card-kec.png';
import onSiteMessagingCoverImage from '../../../assets/app-card-osm.png';
import signInWithKlarnaCoverImage from '../../../assets/app-card-siwk.png';
import { useMid } from './useMid';
const boosterApps = {
    'kec-fe': {
        imageUrl: expressCheckoutCoverImage,
        sortOrder: 2
    },
    'siwk-fe': {
        imageUrl: signInWithKlarnaCoverImage,
        sortOrder: 3
    },
    'upstream-fe': {
        imageUrl: onSiteMessagingCoverImage,
        sortOrder: 1
    }
};
function prepareCardData(apps, t) {
    const cardData = apps.map(app => (Object.assign({ description: t(`home-fe.boosters-home-v03.booster-apps.${app.clientId}.description`), callToAction: t(`home-fe.boosters-home-v03.booster-apps.${app.clientId}.callToAction`), clientId: app.clientId, url: app.url }, boosterApps[app.clientId])));
    const sortedCardData = cardData.sort((a, b) => a.sortOrder - b.sortOrder);
    return sortedCardData;
}
export default function useAccessibleBoosterApps(t) {
    const userAccessibleBoosterApps = useUserAccessibleApps(Object.keys(boosterApps));
    const { selectedMid } = useMid();
    const tokenHelper = coreHooks.useTokenHelper();
    const clientIds = tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getClientIdsForMid(selectedMid !== null && selectedMid !== void 0 ? selectedMid : '');
    const midAccessibleBoosterApps = userAccessibleBoosterApps
        .filter(app => clientIds === null || clientIds === void 0 ? void 0 : clientIds.includes(app.clientId));
    const apps = prepareCardData(midAccessibleBoosterApps, t);
    return apps;
}
