import { __awaiter } from "tslib";
import * as Sentry from '@sentry/react';
export const postUserInfo = (chatClient, region, freshchatUserId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield chatClient.request({
            method: 'POST',
            url: '/v1/chat/user/information',
            region: region,
            data: { freshchat_user_uuid: freshchatUserId }
        });
    }
    catch (err) {
        // @ts-ignore
        if ([401, 403].includes(err === null || err === void 0 ? void 0 : err.status))
            return;
        Sentry.captureException(err);
    }
});
