import {
  RadioGroup,
  Separator,
  SpacerVertical,
  Typography
} from '@klarna/bubble-ui'
import { Layout } from '@klarna/mp-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import PropTypes from 'prop-types'
import React from 'react'

const InviteChoices = {
  ACCEPT: 'Accept',
  DECLINE: 'Decline'
}

const MidInvite = ({ hasError, isDisabled, mid, handleMidInviteChoice, storeName, value }) => {
  const t = i18nHooks.useTranslator()

  const handleOnChange = (value) => {
    handleMidInviteChoice({ mid, storeName, value })
  }

  return (
    <>
      <Separator />
      <SpacerVertical spaceToken='space.500' />
      <div>
        <Layout.Grid>
          <Layout.Section alignItems='center'>
            <Layout.Column mobileWidth={6}>
              {storeName && (
                <Typography
                  colorToken={hasError ? 'colors/text/error' : 'colors/text/default'}
                  textToken='text-style/text/paragraphs/body/bold'
                >
                  {storeName}
                </Typography>
              )}
              <Typography
                colorToken={hasError ? 'colors/text/error' : 'colors/text/neutral'}
                textToken='text-style/text/paragraphs/body/regular'
              >
                {mid}
              </Typography>
            </Layout.Column>
            <Layout.Column mobileWidth={6}>
              <RadioGroup
                isDisabled={isDisabled}
                isHorizontal
                onChange={handleOnChange}
                options={[
                  { value: InviteChoices.ACCEPT, label: t('home-fe.accept-invite-modal.invite.accept-label') },
                  { value: InviteChoices.DECLINE, label: t('home-fe.accept-invite-modal.invite.decline-label') }
                ]}
                value={value}
              />
            </Layout.Column>
          </Layout.Section>
        </Layout.Grid>
      </div>
      <SpacerVertical spaceToken='space.500' />
    </>
  )
}

export default MidInvite

MidInvite.propTypes = {
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  mid: PropTypes.string.isRequired,
  handleMidInviteChoice: PropTypes.func.isRequired,
  storeName: PropTypes.string,
  value: PropTypes.string
}

MidInvite.defaultProps = {
  hasError: false,
  isDisabled: false
}

export {
  InviteChoices
}
