import 'normalize.css';
import './base.css';
import { Root } from '@merchant-portal/core';
import { translations as credentialsTranslations } from '@merchant-portal/credentials';
import { FeatureHighlightInfoProvider, translations as frameworkTranslations } from '@merchant-portal/framework';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { CMSContextProvider } from 'mage-cms';
import { AuthenticationContextProvider, translations as componentTranslations } from 'mage-components';
import { translations as conversationTranslations } from 'mage-conversation';
import { init as coreInit, selectors as coreSelectors } from 'mage-core';
import { init as insightsInit } from 'mage-insights';
import { configureState, configureStore } from 'mage-state';
import * as R from 'ramda';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import uuid from 'uuid/v4';
import ErrorBoundary from './components/ErrorBoundary';
const initSentry = (environment, region, sessionId) => {
    const dsn = R.path(['config', 'common', 'sentry', 'dsn'], window);
    Sentry.init({
        dsn,
        environment,
        normalizeDepth: 5
    });
    Sentry.configureScope(function (scope) {
        scope.setTags({
            clientId: window.clientId,
            sessionId,
            region
        });
    });
};
function isSessionStorageAvailable() {
    try {
        return !!window.sessionStorage;
    }
    catch (error) {
        return false;
    }
}
function initializeSessionId() {
    if (isSessionStorageAvailable()) {
        try {
            const SESSION_ID_KEY = 'mage-app.sessionId';
            const storageSessionId = window.sessionStorage.getItem(SESSION_ID_KEY);
            const sessionId = storageSessionId || uuid();
            if (!storageSessionId) {
                window.sessionStorage.setItem(SESSION_ID_KEY, sessionId);
            }
            return sessionId;
        }
        catch (error) {
            return uuid();
        }
    }
    else {
        return uuid();
    }
}
export default (App, options = {}) => {
    const sessionId = initializeSessionId();
    const isSentryEnabled = R.pathOr(true, ['config', 'common', 'sentry', 'enabled'], window);
    const { container = document.getElementById('root'), createState = () => ({}), createReducers = () => ({}), createMiddlewares = () => ([]), epics = [], Framework, isPublic = false, translations = {}, w = window } = options;
    const state = configureState(w, createState, [translations, conversationTranslations, credentialsTranslations, ...componentTranslations, frameworkTranslations], { sessionId });
    const history = createBrowserHistory({
        basename: coreSelectors.getBasename(state)
    });
    // @ts-ignore: fix configureStore types
    const store = configureStore(state, history, createReducers, createMiddlewares, epics);
    if (isSentryEnabled) {
        const environment = R.pathOr('production', ['config', 'common', 'environment', 'type'], window);
        const region = R.pathOr('eu', ['config', 'common', 'environment', 'region'], window).toLowerCase();
        initSentry(environment, region, sessionId);
    }
    coreInit(store, window.location);
    insightsInit(window.clientId, state.config);
    const designVersion = coreSelectors.getDesignVersion(state);
    render((
    // @ts-ignore: fix FeatureHighlightInfoProvider types
    React.createElement(FeatureHighlightInfoProvider, null,
        React.createElement(Provider, { store: store },
            React.createElement(ErrorBoundary, { designVersion: designVersion },
                React.createElement(CMSContextProvider, null,
                    React.createElement(AuthenticationContextProvider, { initialAuthenticationRequired: !isPublic },
                        React.createElement(Root, { App: App, Framework: Framework, history: history, triggerGtmEvent: window.triggerGtmEvent }))))))), container);
};
