import { LOCATION_CHANGE } from 'connected-react-router'
import * as R from 'ramda'
import { handleActions } from 'redux-actions'

import a from './actions'

const init = {
  clientId: null,
  slug: null,
  authenticated: false,
  accessToken: undefined,
  token: undefined,
  realm: 'merchants',
  mids: [],
  merchants: [],
  selectedMid: undefined,
  isUpdateProfileLoading: false,
  isUpdateProfileFailed: false,
  designVersion: null,
  sessionId: undefined,
  requester: undefined,
  isPartner: false
}

export default handleActions({
  [a.token.set]: (state, { payload: keycloak }) => ({
    ...state,
    authenticated: true,
    accessToken: keycloak.token
  }),
  [a.keycloak.realm.set]: (state, { payload }) => ({
    ...state,
    realm: payload
  }),
  [a.keycloak.realm.clear]: state => ({
    ...state,
    realm: 'merchants'
  }),
  [a.profile.updating]: state => ({
    ...state,
    isUpdateProfileLoading: true,
    isUpdateProfileFailed: false
  }),
  [a.profile.update.succeeded]: state => ({
    ...state,
    isUpdateProfileLoading: false,
    isUpdateProfileFailed: false
  }),
  [a.profile.update.failed]: state => ({
    ...state,
    isUpdateProfileFailed: true,
    isUpdateProfileLoading: false
  }),
  [a.requester.fetch]: (state, { error, payload }) => {
    return (error || !payload)
      ? state
      : {
        ...state,
        requester: payload
      }
  },
  [a.requester.update]: (state, { error, payload }) => ({
    ...state,
    requester: {
      ...state.requester,
      ...(error ? {} : payload)
    }
  }),
  [a.requester.set]: (state, { payload }) => ({
    ...state,
    requester: payload
  }),
  [a.merchants.set]: (state, { payload: merchants = init.merchants }) => {
    const sortedMerchants = R.sortBy(R.prop('merchant_id'), merchants)
    return (R.equals(state.merchants, sortedMerchants))
      ? state
      : {
        ...state,
        merchants: sortedMerchants
      }
  },
  [a.mids.set]: (state, { payload: mids = init.mids }) => {
    if (R.equals(state.mids, mids)) return state

    return {
      ...state,
      mids: mids,
      selectedMid: state.selectedMid || mids[0]
    }
  },
  [a.mid.set]: (state, { payload: selectedMid }) => ({
    ...state,
    selectedMid
  }),
  [a.isPartner.set]: (state, { payload: isPartner }) => ({
    ...state,
    isPartner
  }),
  [LOCATION_CHANGE]: (state, action) => {
    const metadata = action.metadata
    if (!metadata) return state
    const slug = (metadata.url.startsWith('/') && metadata.url.length > 1) ? metadata.url.substring(1) : metadata.url
    return {
      ...state,
      clientId: metadata.clientId,
      slug
    }
  }
}, init)

export {
  init
}
