import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import TrackableLink from '../../../../../components/TrackableLink';
export default function SetupBoostInfo() {
    const t = i18nHooks.useTranslator();
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { accessibilityPreset: 'heading 2', textToken: 'text-style/headings/titles/bold/grande' }, t('home-fe.boosters-home-v03.setup-boost-info.title')),
        React.createElement(SpacerVertical, { spaceToken: 'space.100' }),
        React.createElement(Typography, { colorToken: 'colors/text/neutral' },
            t('home-fe.boosters-home-v03.setup-boost-info.description'),
            "\u00A0",
            React.createElement(TrackableLink, { href: 'https://docs.klarna.com/conversion-boosters/?utm_source=Merchant%20Portal&utm_medium=Sidebar%20Menu&utm_campaign=external_referral', openInNewWindow: true, isUnderlined: true, colorToken: 'colors/text/neutral', category: "home-fe/v3/boost-home/integration-type/klarna-docs" }, t('home-fe.boosters-home-v03.setup-boost-info.link-label')))));
}
