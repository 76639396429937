module.exports = {
	"en": {
		"home-fe": {
			"boosters-home-v03": {
				"setup-boost-info": {
					"title": "Choose your integration type",
					"description": "Please select the integration option that best aligns with your requirements.",
					"link-label": "Learn more"
				},
				"booster-apps": {
					"title": "Select each feature individually from the options available.",
					"kec-fe": {
						"description": "Offer a 5x faster check-out process that will lower the threshold for shoppers to complete a purchase.",
						"callToAction": "Activate Express checkout"
					},
					"siwk-fe": {
						"description": "Let customers connect their accounts with pre-set payments preferences for a personalized shopping experience.",
						"callToAction": "Activate Sign-in with Klarna"
					},
					"upstream-fe": {
						"description": "Add personalized messaging throughout the shopper journey for higher conversion rates and increased spend.",
						"callToAction": "Activate On-site messaging"
					}
				},
				"install-plugin": {
					"stepNumber": "1",
					"title": "Install plugin",
					"description": "Select your e-commerce platform so we can create a link to speed up your installation",
					"selectorLabel": "Select platform",
					"getPluginButtonLabel": "Get plugin",
					"klarnaDocsLinkLabel": "Learn more on Klarna docs"
				},
				"start-setup": {
					"stepNumber": "2",
					"title": "Enable plugin",
					"description": "Generate and copy the client identifier and API key, you will need these to enable the plugin."
				},
				"promotion-banner": {
					"title": "Klarna conversion boosters",
					"subtitle": "Maximize sales with Klarna. Complete your integration and instantly benefit from the power of our conversion boosters that are designed to drive sales.",
					"banner-cards": {
						"osm-title": "Increase average order value with <span>On-site messaging</span>",
						"kec-title": "Drive higher conversion with Express checkout",
						"siwk-title": "Attract new customers with <span>Sign in with Klarna</span>"
					}
				},
				"configure-boost-features": {
					"stepNumber": "3",
					"title": "Tailor feature placements",
					"description": "This is an optional step to change how the features look on your store. To do this you should have completed step 2 already.",
					"no-credentials": "Make sure first to generate the client identifier and API key in order to continue this step.",
					"apps": {
						"osm": {
							"link-label": "Tailor On-site messaging",
							"description": "Tailor on-site messaging to fit your brand by adjusting the theme and selecting placements"
						},
						"kec": {
							"link-label": "Tailor Express checkout",
							"description": "Tailor the express checkout button to fit your brand by adjusting the button theme, shape and selecting placements"
						},
						"siwk": {
							"link-label": "Setup Sign-in with Klarna",
							"description": "Select the scope for sign-in with Klarna and add a re-direct URL. The scope is the information collected from your customer, and the re-direct URL is what is used if pop-ups are blocked by customers"
						}
					}
				},
				"client-identifier-section": {
					"title": "Client identifier",
					"description": "The unique identifier for set up",
					"manage-link": "Manage client identifier and origin",
					"generate-button": "Generate",
					"tooltip": "Generate and copy the client identifier. You need these to start the plugin setup on your platform’s portal."
				},
				"api-key": {
					"warning-banner": {
						"content": "You already have active API keys. Before generating new ones, make sure you have deactivated any you are not using."
					},
					"title": "Klarna API key",
					"description": "API key for set up",
					"tooltip": "Please note that we do not display your secret API key again after you generate it.",
					"generate-button": "Generate",
					"manageApiKey": "Manage API key"
				},
				"non-platform-integration": {
					"question": "Not using Klarna for platforms?",
					"link": "See how to directly integrate with Klarna"
				},
				"tabs": {
					"plugin": "Setup with Plugin",
					"websdk": "Setup with WebSDK"
				}
			},
			"boosters-home": {
				"welcome-banner": {
					"title": "Unlock the full power of Klarna",
					"text": "Features designed to turn visitors into power shoppers. Enhance every step of the shopping experience - from browsing to checkout."
				},
				"plugin-carousel": {
					"title": "No code setup with a plugin",
					"overviewLink": "View all platforms",
					"directIntegrationLabel": "Not using Klarna for platforms?",
					"directIntegrationLink": "See how to directly integrate with Klarna"
				},
				"booster-apps": {
					"title": "Learn more",
					"kec-fe": {
						"title": "Make check-out 5x faster",
						"description": "Less clicks mean less drop off. Drive conversion and minimize cart abandonment with express checkout on product and cart pages.",
						"callToAction": "More about Express Checkout"
					},
					"siwk-fe": {
						"title": "Social sign in for shopping",
						"description": "Shoppers get a fast and secure one-click sign in option. You get enhanced, actionable shopper profile data.",
						"callToAction": "More about Sign in with Klarna"
					},
					"upstream-fe": {
						"title": "Turn visitors into shoppers",
						"description": "Maximize conversion with dynamic promotional messaging throughout the shopping journey.",
						"callToAction": "More about On-site messaging"
					},
					"branding-fe": {
						"title": "Improve your store’s visibility",
						"description": "Manage your brand in the Klarna app by uploading a store logo, icon, and URL.",
						"callToAction": "Set up Brand manager"
					}
				}
			},
			"boosters-plugins": {
				"title": "Plugin setup",
				"description": "Get started with our plugins and make sure you're ready to go live."
			},
			"boosters-plugin": {
				"title": "Klarna for {pluginName}",
				"description": "Take the actions below to enable Klarna payments and/or boost features with our no code setup.",
				"access-warning": {
					"both": "You do not have permission for {selectedStoreName} ({selectedMid}) to generate API keys and client identifier. Please contact your admin user and ask for access to Payment settings to proceed.",
					"client": "You do not have permission for {selectedStoreName} ({selectedMid}) to generate client identifier. Please contact your admin user and ask for access to Payment settings to proceed.",
					"api": "You do not have permission for {selectedStoreName} ({selectedMid}) to generate API keys. Please contact your admin user and ask for access to Payment settings to proceed."
				},
				"back": "Back",
				"instructions": {
					"api-key": {
						"title": "Enable Klarna payment",
						"content": "You need to generate and copy API keys so that you can use them during setup.",
						"link-label": "Learn more on Klarna docs.",
						"generate": "Generate API keys",
						"active-warning": {
							"content": "You already have active API keys. Before generating new ones, make sure you have deactivated any you are not using.",
							"link": "Manage API keys"
						},
						"active-warning-api-keys": {
							"content": "You already have active API keys. Before generating new ones, make sure you have deactivated any you are not using.",
							"link": "Manage API keys"
						}
					},
					"client-id": {
						"title": "Enable boost features",
						"content": "To add Klarna express checkout and On-site messaging you need to generate and copy your client identifier so you can use them during setup.",
						"generate": "Generate client identifier",
						"manage": "Manage client identifier"
					},
					"plugin-doc": {
						"title": "Configure boost features",
						"content": "To finalise your setup you need to configure Klarna express checkout and On-site messaging in your platform’s settings."
					}
				},
				"error-messages": {
					"default": "An error has occurred. Please try again later or go to help.",
					"fetch-data": "An error has occurred when fetching your data. Please try again later or go to help.",
					"create-api-key": "An error has occurred when creating your API keys. Please try again later or go to help.",
					"create-client-id": "An error has occurred when creating your client identifier. Please try again later or go to help."
				}
			},
			"marketing-home": {
				"description": "Welcome to our marketing products. Start exploring below.",
				"essentials": {
					"title": "Marketing",
					"cta-label": "Learn more",
					"branding-fe": {
						"description": "Set up and manage your brand. Make customers see you in the best light across Klarna's ecosystem including our smoooth app."
					},
					"klarna-search-b2b-portal": {
						"description": "Get started with Klarna’s powerful search engine and comparison shopping. Gain access to statistics and tools designed to enhance your visibility."
					}
				},
				"external-apps": {
					"title": "Apps",
					"cta-label": "Open app"
				},
				"popOvers": {
					"searchCompare": {
						"title": "Get started with Search and Compare",
						"content": "Check here for statistics and tools that can help increase your visibility on Klarna's comparison shopping service."
					}
				}
			},
			"no-apps": {
				"title": "No apps available!",
				"subtitle": "Contact your admin to help you access the apps you need."
			},
			"contact": {
				"title": "Do you need help with anything?",
				"paragraph": "If you have any questions don’t hesitate to reach out and we’ll help answering them.",
				"label": "Contact us"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "Make your account more secure with two-factor authentication.",
				"banner-link": "Set it up"
			},
			"two-factor-opt-in": {
				"title": "Set up two-factor authentication",
				"intro-paragraph": "To increase the security of your account we encourage you to set up two-factor authentication today.",
				"help-link-text": "Click here",
				"help-text": " to learn more about how to set it up and how it protects you.",
				"warning-text": "Make sure you have your smartphone and access to the internet before you proceed",
				"continue-button": "Start the setup"
			},
			"accept-invite-modal": {
				"title": "Accept Pending Invites",
				"description": "You’ve been invited to access the following stores. Please note that it might take a few minutes for your choices to take effect.",
				"accept-all-button": "Accept All",
				"decline-all-button": "Decline All",
				"save-button": "Save",
				"error": "There has been an issue. Please try again later!",
				"invite": {
					"accept-label": "Accept",
					"decline-label": "Decline"
				}
			},
			"capture-orientation": {
				"title": "First time Klarna user?",
				"sub-title": "Follow these steps to learn the basics:",
				"order-label": "Receive an order",
				"capture-label": "Capture the order",
				"payout-label": "Get paid",
				"capture-cta": "Capture this order",
				"payout-cta": "Settlements app",
				"dismiss-cta": "Dismiss",
				"order-step": "Before anything else, we should wait for an incoming order from your customers.",
				"capture-step": "Congratulations on your first order.\nTo get paid, the next step is to ship and capture your order.",
				"payout-step": "Congratulations on capturing your first order.\nYou will now receive a payout from us inline with the the timings defined in your Klarna Agreement.\nFor further details on your first payout go to the",
				"completed-step": "Congratulations on your first payout.\nWelcome to a beautiful journey with Klarna.\nFeel free to explore the other apps we offer below."
			},
			"banner": {
				"common": {
					"branding-fe": {
						"content": "We've just launched our new Branding app!",
						"linkLabel": "Take control of your brand right now!"
					},
					"onSiteMessaging": {
						"title": "Turn visitor into shoppers with On-site messaging",
						"text": "Shopping begins before your customers reach the cart. With On-site messaging, you will increase awareness of Klarna’s flexible payment options and other benefits early in the sales funnel.",
						"cta": "Check it out"
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "We are rolling out some exciting features for your business.",
					"subtitle": "And, we would like to know you better.",
					"explore": "Yes, let’s explore this!",
					"name-field": "So, what should we call you?",
					"terms-pre": "I’ve read and agreed to the ",
					"terms-text": "Terms & Conditions"
				}
			},
			"insightsWidget": {
				"title": "Sales today",
				"orderCount": "Created orders",
				"orderVolume": "Sales volume",
				"orderVolumeAverage": "Average order value",
				"currencyFootnote": "Displayed in {currency}",
				"link": "See orders",
				"allStores": "All stores",
				"selectAllOptionText": "Select all",
				"deselectAllOptionText": "Clear Selection",
				"closeText": "Done",
				"closeTextWithSelection": "Apply # selection(s)",
				"elapsedTime": "Last order received {hours, plural, =0 {} =1 {one hour} other {# hours}} {minutes, plural, =0 {} =1 {one minute} other {# minutes}} ago",
				"error": {
					"content": "Unfortunately your order statistics didn't load correctly."
				},
				"relativeDate": {
					"today": "Today",
					"yesterday": "Yesterday"
				},
				"weekDays": {
					"1": "Monday",
					"2": "Tuesday",
					"3": "Wednesday",
					"4": "Thursday",
					"5": "Friday",
					"6": "Saturday",
					"7": "Sunday"
				}
			},
			"blackFridayWidget": {
				"title": "Black Friday week is here.",
				"text": "Follow your order statistics in near-time.",
				"button": "View statistics"
			},
			"tasks": {
				"title": "To-dos",
				"error": {
					"title": "We couldn't load the to-do list",
					"desc": "Please reload the page, so we can try again."
				},
				"empty": {
					"title": "You are all good!",
					"desc": "You have no open tasks right now."
				},
				"single": {
					"products-fe:complete-recollection": "Mandatory AML form to fill out for your business to prevent money laundering",
					"products-fe:resume-onboarding": "Complete your onboarding",
					"settings-fe:compile-cs-info": "Add your customer service information"
				},
				"aggregated": {
					"disputes-fe:unresolved": "{ count, plural, =1 {1 unresolved dispute} other {# unresolved disputes}}",
					"orders-fe:expiring": "{ count, plural, =1 {1 expiring order} other {# expiring orders}}",
					"orders-fe:tocapture": "{ count, plural, =1 {1 order to capture} other {# orders to capture}}",
					"orders-fe:uncaptured": "{ count, plural, =1 {1 uncaptured order} other {# uncaptured orders}}",
					"users-fe:mid-added": "{ count, plural, =1 {1 new store has been added} other {# new stores have been added}}",
					"users-fe:mid-invited": "{ count, plural, =1 {1 store invite awaiting action} other {# store invites awaiting action}}"
				}
			}
		}
	}
};