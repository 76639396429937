import { Typography } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import styled from 'styled-components';
import { useActiveBoostProducts } from '../../hooks/useActiveBoostProducts';
const TRANSLATION_PREFIX = 'home-fe.boosters-home-v03.configure-boost-features';
const InnerContainer = styled.div.withConfig({ displayName: "InnerContainer", componentId: "sc-5zo4ln" }) `
  text-align: left;
`;
export default function NoCredentialsMessage() {
    const t = i18nHooks.useTranslator();
    const { areCredentialsReady } = useActiveBoostProducts();
    if (areCredentialsReady)
        return React.createElement(React.Fragment, null);
    return (React.createElement(InnerContainer, null,
        React.createElement(Typography, { textToken: "text-style/text/paragraphs/body/regular" }, t(`${TRANSLATION_PREFIX}.no-credentials`))));
}
