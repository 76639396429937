import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Flex, useResponsiveContext } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import styled from 'styled-components';
import promotionBannerMobile from '../../../../assets/promotion-banner-mobile.png';
import promotionBannerTablet from '../../../../assets/promotion-banner-tablet.png';
function getContainerFlexProps(isMobile) {
    return {
        style: {
            maxWidth: '1132px',
            margin: '0 auto',
            paddingBottom: '20px',
            gap: isMobile ? '40px' : '32px'
        },
        children: null
    };
}
const bannerCardContainerFlexProps = {
    justifyContent: 'center',
    alignItems: 'center',
    style: {
        gap: 'calc(10% / 3)',
        flexWrap: 'nowrap',
        width: '100%'
    },
    children: null
};
const Image = styled.img.withConfig({ displayName: "Image", componentId: "sc-r1ubex" }) `
  max-width: 100%;
`;
export default function MobileView() {
    const t = i18nHooks.useTranslator();
    const { isMobile } = useResponsiveContext();
    return (React.createElement(Flex, Object.assign({}, getContainerFlexProps(isMobile)),
        React.createElement(Flex, null,
            React.createElement(Typography, { textToken: 'text-style/headings/titles/bold/tertiary' }, t('home-fe.boosters-home-v03.promotion-banner.title')),
            React.createElement(SpacerVertical, { spaceToken: isMobile ? 'space.300' : 'space.200' }),
            React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular', accessibilityPreset: 'paragraph' }, t('home-fe.boosters-home-v03.promotion-banner.subtitle'))),
        React.createElement(Flex, Object.assign({}, bannerCardContainerFlexProps),
            React.createElement(Image, { src: isMobile ? promotionBannerMobile : promotionBannerTablet }))));
}
