import { ButtonSecondary, DesignTokens, Typography } from '@klarna/bubble-ui';
import Insights from 'mage-insights';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import CardWrapper from './CardWrapper';
const TopAlignment = styled.div.withConfig({ displayName: "TopAlignment", componentId: "sc-haco57" }) `
  height: 100%;
`;
const Image = styled.div.withConfig({ displayName: "Image", componentId: "sc-wfptu0" }) `
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: url('${({ imageUrl }) => imageUrl}');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: bottom center;
  border-radius: 8px;
  max-height: 200px;
  min-height: 200px;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 45.28%;
  }
`;
const CallToActionWrapper = styled.div.withConfig({ displayName: "CallToActionWrapper", componentId: "sc-xywn5c" }) `
  margin-top: 24px;
`;
const Heading = styled.div.withConfig({ displayName: "Heading", componentId: "sc-1edjtep" }) `
  margin-bottom: 8px;
`;
function AppCard({ title, imageUrl, description, backgroundColor, clientId, url, buttonLabel, componentRef }) {
    const category = `marketing-home/app-card/${clientId}`;
    const designContext = DesignTokens.useDesignContext();
    const TextWrapper = styled.div.withConfig({ displayName: "TextWrapper", componentId: "sc-1sm24nv" }) `
    background: ${designContext.get('colors/backgrounds/default').toString()};
    padding-top: 16px;
    text-align: left;
  `;
    useEffect(() => {
        Insights.event({
            category,
            action: 'impression'
        });
    }, []);
    const onClick = () => {
        Insights.event({
            category,
            action: 'click'
        });
        location.href = url;
    };
    return (React.createElement("div", { ref: componentRef },
        React.createElement(CardWrapper, { onClick: onClick },
            React.createElement(TopAlignment, null,
                React.createElement(Image, { backgroundColor: backgroundColor, imageUrl: imageUrl }),
                React.createElement(TextWrapper, null,
                    React.createElement(Heading, null,
                        React.createElement(Typography, { textToken: 'text-style/headings/titles/bold/grande' }, title)),
                    React.createElement(Typography, { textToken: 'text-style/text/paragraphs/default/regular' }, description),
                    React.createElement(CallToActionWrapper, null,
                        React.createElement(ButtonSecondary, { size: 'medium', onClick: onClick, onPress: onClick }, buttonLabel)))))));
}
export default AppCard;
