import * as Sentry from '@sentry/react'
import { memoizeAsync } from './utils/memoize'

const MINUTE_MS = 60 * 1000

export const incrConversationsMetric = memoizeAsync(async ({ chatClient, env, region, market }) => {
  try {
    console.log('env', env)
    console.log('region', region)
    console.log('market', market)
    await chatClient.request({
      method: 'POST',
      url: '/v1/metrics/conversations',
      region: region,
      data: { environment: env, market }
    })
  } catch (err) {
    if ([401, 403].includes(err?.status)) return

    Sentry.captureException(err)
  }
}, MINUTE_MS * 5)
