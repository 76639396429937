import { __rest } from "tslib";
import { MaskedField } from '@klarna/bubble-ui';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import useLabelTranslation from '../hooks/useLabelTranslation';
import useValidationErrorLabel from '../hooks/useValidationErrorLabel';
import formatErrorLabel from '../utils/formatErrorLabel';
DateField.propTypes = {
    name: PropTypes.string.isRequired
};
export default function DateField(_a) {
    var { name } = _a, props = __rest(_a, ["name"]);
    const label = useLabelTranslation(name);
    const errorMessage = useValidationErrorLabel(name);
    const [field, , { setValue }] = useField(name);
    return (React.createElement(MaskedField, Object.assign({}, field, props, { error: errorMessage !== undefined, label: errorMessage ? formatErrorLabel(label, errorMessage) : label, onChangeMaskedValue: setValue, mask: [null, null, '-', null, null, '-', null, null, null, null], placeholder: 'mm-dd-yyyy' })));
}
