import { __awaiter } from "tslib";
import { useHelpContext } from './';
import { startConversationUserInfo } from 'mage-conversation';
import { useChannelDataState } from '../context/ChannelDataStateProvider';
import { useChannelSelectorState } from '../context/ChannelSelectorState';
import { gatherCallbacks } from '../utilities/callback';
import { generateSidebarController } from '../utilities/sidebarController';
import { useChatContext } from './useChatContext';
import { useRefreshClientGenerator } from './useRefreshClientGenerator';
import useIsChatActive from './useIsChatActive';
import { hooks as i18nHooks } from 'mage-i18n';
import { useChatClient } from './useChatClient';
const useContactChannels = () => {
    useRefreshClientGenerator();
    // @ts-ignore
    const helpHook = useHelpContext();
    // @ts-ignore
    const { toggleWidget } = useHelpContext();
    const { data: channelData } = useChannelDataState();
    const { resetIsChatLoading } = useChannelSelectorState();
    const chatFeatures = useIsChatActive();
    const navigateToCreateTicketPage = () => {
        helpHook.navigation.push('support');
    };
    const sidebarController = generateSidebarController(toggleWidget);
    const context = useChatContext();
    const language = i18nHooks.useLanguage();
    const client = useChatClient();
    const closeViewport = () => sidebarController(false);
    const getChatContext = (marketCode, tag) => {
        const region = chatFeatures.getWorkingHoursRegion(marketCode);
        const restoreIdByRegion = channelData.restoreId;
        const onChatWidgetOpens = gatherCallbacks([closeViewport, resetIsChatLoading]);
        return Object.assign(Object.assign({}, context), { region, market: marketCode, restoreIdByRegion,
            onChatWidgetOpens,
            language,
            tag });
    };
    const getFreshchatTag = (marketCode) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield client.request({
            method: 'GET',
            url: `/v1/tags/${marketCode.toLocaleLowerCase()}`,
            headers: { 'Content-Type': 'application/json' }
        });
        return response.data;
    });
    const startChatConversation = (marketCode) => {
        getFreshchatTag(marketCode)
            .then(tag => {
            return startConversationUserInfo(getChatContext(marketCode, tag));
        })
            .catch(() => {
            return startConversationUserInfo(getChatContext(marketCode));
        });
    };
    return {
        navigateToCreateTicketPage,
        startChatConversation
    };
};
export default useContactChannels;
