import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import CardContainer from '../../../../../components/AppCard/CardContainer';
import useAccessibleBoosterApps from '../hooks/useAccessibleBoosterApps';
import AppCard from './AppCard';
function WebSdkTabContent() {
    const t = i18nHooks.useTranslator();
    const apps = useAccessibleBoosterApps(t);
    if (apps.length === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular', colorToken: 'colors/text/neutral' }, t('home-fe.boosters-home-v03.booster-apps.title')),
        React.createElement(SpacerVertical, { spaceToken: 'space.400' }),
        React.createElement(CardContainer, null, apps.map(app => (React.createElement(AppCard, { key: app.clientId, description: app.description, imageUrl: app.imageUrl, clientId: app.clientId, url: app.url, buttonLabel: app.callToAction }))))));
}
export default WebSdkTabContent;
