import { selectors as coreSelectors } from 'mage-core';
import React from 'react';
import { useSelector } from 'react-redux';
import LoaderOverlay from './LoaderOverlay';
export default function WaitForMids({ children }) {
    const merchants = useSelector(coreSelectors.getMerchants);
    if ((merchants === null || merchants === void 0 ? void 0 : merchants.length) > 0) {
        return children;
    }
    else {
        return React.createElement(LoaderOverlay, null);
    }
}
